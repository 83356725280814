var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "projects-container" },
    _vm._l(_vm.projects, function (project, index) {
      return _c("client-project-block", {
        key: `${project.ref}_${index}`,
        attrs: { project: project, client: _vm.client },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
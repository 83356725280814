var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    {
      staticClass: "card",
      style: { justifyContent: _vm.align },
      attrs: { shadow: "never" },
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          !_vm.loading
            ? [_vm._t("default")]
            : _c("div", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: {
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "rgba(0, 0, 0, 0)",
                },
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _vm.client
        ? _c("breadcrumb-header", {
            attrs: {
              labels: [_vm.client.name],
              go_back_to: "management_clients",
            },
          })
        : _vm._e(),
      _vm.client
        ? _c(
            "div",
            { staticClass: "inner" },
            [
              _c(
                "content-block",
                { attrs: { title: "Projects" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { float: "right" },
                      attrs: { slot: "right" },
                      slot: "right",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.linkProject },
                        },
                        [_vm._v(" Link ")]
                      ),
                    ],
                    1
                  ),
                  _c("client-projects", { attrs: { client: _vm.client } }),
                ],
                1
              ),
              _c(
                "content-block",
                { attrs: { title: "Contact" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "contact-buttons",
                      attrs: { slot: "right" },
                      slot: "right",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", loading: _vm.updating },
                          on: { click: _vm.updateContact },
                        },
                        [_vm._v(" Update from Xero ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.editOnXero },
                        },
                        [_vm._v(" Edit ")]
                      ),
                    ],
                    1
                  ),
                  !_vm.client.email
                    ? _c(
                        "el-alert",
                        { attrs: { type: "error", closable: false } },
                        [_vm._v(" Client is missing primary contact email. ")]
                      )
                    : _vm._e(),
                  _c("client-form", {
                    attrs: { client: _vm.client, readonly: "", loading: false },
                  }),
                ],
                1
              ),
              _c(
                "content-block",
                { attrs: { title: "Invoices" } },
                [_c("client-invoices", { attrs: { client: _vm.client } })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }